import categoryUrls from '../config/category_urls.sync.js'
export const getUrlFromTechnicalName = function(technicalName, locale) {
  return categoryUrls[technicalName] && categoryUrls[technicalName][locale] || technicalName
}

export const getTechnicalNameFromUrl = function(url, locale) {
  const foundTechnicalName = Object.entries(categoryUrls)
    .find(u => u[1] && u[1][locale] && u[1][locale] === url)
  return foundTechnicalName && foundTechnicalName[0] || url
}

export default {
  methods: {
    getCategoryUrlFromTechnicalName(t) {
      return getUrlFromTechnicalName(t, this.$i18n.locale)
    },
    getCategoryTechnicalNameFromUrl(u) {
      return getTechnicalNameFromUrl(u, this.$i18n.locale)
    }
  }
}
